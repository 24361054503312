<template>
	<v-container>
		<h1>Курси</h1>
		<v-row>
			<v-col
				v-for="course in courses"
				:key="course.id"
				cols="6"
				md="4"
				lg="3">
				<v-card
					outlined>
					<v-card-title>
						{{ course.name }}
					</v-card-title>

					<v-card-text>
						<p>Викладач: {{ course.author.full_name }} </p>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							:to="{
								name: 'course',
								params: {
									id: course.id,
								},
							}"
							outlined
							small>
							далі
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import Course from '../models/Course'

export default {
	data: () => ({
		search: '',
		loading: false,
	}),
	computed: {
		courses() {
			return Course.query()
				.withAll()
				.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	mounted() {
		this.loadCourses()
	},
}
</script>